
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
@Component({
  components: { Icons }
})
export default class KeyElementsEdit extends Vue {
  @Prop({ default: () => [''] }) keyElements!: string[]
  @Prop({ default: 6 }) limitMax!: number
  @Prop({ default: 'Description of element' }) placeHolder!: string
  private elements = this.keyElements

  setElements() {
    if (this.elements && this.elements.length > 0) {
      for (let index = 0; index < this.elements.length; index++) {
        if (this.elements[index] === '') this.elements.splice(index, 1)
      }
    }
    this.$emit('set-key-elements', this.elements)
  }

  addNewElement() {
    if (!this.elements) {
      this.elements = ['']
    } else {
      if (this.elements[this.elements.length - 1] !== '') this.elements.push('')
    }
  }

  @Watch('keyElements')
  getElements(newValue: string[]) {
    this.elements = newValue
  }
}
