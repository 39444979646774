import axios from 'axios'
import {
  NewContentDto,
  UpdateContentDto,
  Content,
  ContentView
} from '@bertazzoni/common/src/models/content.model'
import { buildUrl, PaginationData } from '@bertazzoni/common/src/helpers/urlFactory'

const API_URL = process.env.VUE_APP_API_ENDPOINT + '/contents'

class ContentService {
  async findAll(): Promise<Content[]> {
    const response = await axios.get(`${API_URL}/findAll`)
    return response.data
  }

  async findAllPaginated(
    data: PaginationData
  ): Promise<{ results: Content[]; total: number; offset: number; limit: number }> {
    let url = `${API_URL}/findAllPaginated/filter?limit=${data.limit}`
    url = buildUrl(data, url)
    const response = await axios.get(url)
    return response.data
  }

  async findOne(id: string): Promise<Content> {
    const response = await axios.get(`${API_URL}/findOne/${id}`)
    return response.data
  }
  async removeOne(moduleId: string): Promise<void> {
    return await axios.delete(`${API_URL}/removeOne/${moduleId}`)
  }
  async findContentsViewsFromModule(moduleId: string): Promise<ContentView[]> {
    return (await axios.get(`${API_URL}/findContentsViewsFromModule/${moduleId}`)).data as ContentView[]
  }
  async findByIds(searchedIds: Array<string>): Promise<Content[]> {
    const response = await axios.get(`${API_URL}/findByIds?ids=${searchedIds.join(',')}`)
    return response.data
  }
  async getNamesByIds(ids: string[]): Promise<{ name: string; id: string }[]> {
    const response = await axios.get(`${API_URL}/getNamesByIds?ids=${ids}`)
    return response.data
  }
  async createOne(content: NewContentDto): Promise<Content> {
    const response = await axios.post(`${API_URL}/createOne`, content)
    return response.data
  }
  async updateOne(content: UpdateContentDto, id: string): Promise<Content> {
    const response = await axios.put(`${API_URL}/updateOne/${id}`, content)
    return response.data
  }
}

export default new ContentService()
