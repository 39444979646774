










import { Component, Prop, Vue } from 'vue-property-decorator'
import Icons from '@bertazzoni/common/src/components/Icons.vue'

@Component({ components: { Icons } })
export default class FormError extends Vue {
  @Prop({ required: true }) errorText!: string
}
