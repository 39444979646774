export enum TypeContent {
  pdf = 'PDF',
  video = 'VIDEO',
  ppt = 'PPT'
}
export class Content {
  createdAt: Date
  updatedAt: Date
  _id: string
  countryAudienceIds: Array<string>
  keyElements: Array<string>
  title: string
  type: TypeContent
  description: string
  expirationDate: Date
  srcURI: string
  views: number
  language: string
  previewImageURI: string
  time: number
  saw: boolean
}
export class NewContentDto {
  title!: string
  type!: TypeContent
  description?: string
  keyElements?: string[]
  expirationDate?: Date
  previewImageURI!: string
  srcURI!: string
  language!: string
  time!: number

  constructor(keyElements: string[] = [''], language = '') {
    this.keyElements = keyElements
    this.language = language
  }
}
export class UpdateContentDto {
  title: string
  type!: TypeContent
  description: string
  keyElements: string[]
  expirationDate: Date
  previewImageURI: string
  srcURI: string
  language: string
  time: number

  constructor(
    type?: TypeContent,
    title?: string,
    language?: string,
    keyElements?: string[],
    previewImageURI?: string,
    srcURI?: string,
    expirationDate?: Date,
    description?: string,
    time?: number
  ) {
    this.title = title
    this.type = type
    this.description = description
    this.keyElements = keyElements
    this.language = language
    this.expirationDate = expirationDate
    this.previewImageURI = previewImageURI
    this.srcURI = srcURI
    this.time = time
  }
}
export type ContentView = {
    contentId: string,
    views: number
}
