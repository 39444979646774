





































































































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import KeyElementsEdit from '@bertazzoni/back/components/KeyElementsEdit.vue'
import UploadFile from '@bertazzoni/back/components/UploadFile.vue'
import DatePicker from '@bertazzoni/back/components/DatePicker.vue'
import ContentService from '@bertazzoni/common/src/services/ContentService'
import { getLanguageList } from '@bertazzoni/common/src/helpers/utils/utils'
import {
  FileTypeFormat,
  VideoFormat,
  PptFormat
} from '@bertazzoni/common/src/helpers/utils/fileManager'
import { NewContentDto, TypeContent } from '@bertazzoni/common/src/models/content.model'
import FormError from '@bertazzoni/common/src/components/FormError.vue'

@Component({
  components: { Loader, FormError, Icons, UploadFile, DatePicker, KeyElementsEdit }
})
export default class AddContent extends Vue {
  private newContent: NewContentDto
  private pageLoaded = false
  private submitLoading = false
  private formatFile = FileTypeFormat
  private languageList: string[] = []
  private error = { image: false, content: false }

  async created(): Promise<void> {
    this.languageList = await getLanguageList(true)
    this.pageLoaded = true
    this.newContent = new NewContentDto()
  }
  getDate(newDate: Date): void {
    this.newContent.expirationDate = new Date(newDate)
  }
  getImage(newImage: string): void {
    this.newContent.previewImageURI = newImage
    this.checkErrorImg()
  }
  getContent(newContent: string): void {
    this.newContent.srcURI = newContent
    this.checkErrorContent()
  }
  setKeyElement(newKeyElement: string[]): void {
    this.newContent.keyElements = newKeyElement
  }
  getTypeContent(format: string): void {
    if (format in VideoFormat) {
      this.newContent.type = TypeContent.video
    } else if (format in PptFormat) {
      this.newContent.type = TypeContent.ppt
    } else {
      this.newContent.type = TypeContent.pdf
    }
  }
  get validity(): boolean {
    return this.formValid()
  }
  formValid(): boolean {
    const isValid = this.$validator.validateAll()
    this.checkErrorImg()
    this.checkErrorContent()
    return isValid && !this.error.image && !this.error.content
  }
  async checkErrors(): Promise<void> {
    if (this.formValid()) {
      this.submitNewContent()
    }
  }
  checkErrorImg() {
    if (!this.newContent.previewImageURI || this.newContent.previewImageURI === '') {
      this.error.image = true
    } else {
      this.error.image = false
    }
  }
  checkErrorContent() {
    if (!this.newContent.srcURI || this.newContent.srcURI === '') {
      this.error.content = true
    } else {
      this.error.content = false
    }
  }
  async submitNewContent(): Promise<void> {
    this.changeLoading()
    if (
      this.newContent.keyElements &&
      (this.newContent.keyElements.length === 0 ||
        (this.newContent.keyElements.length === 1 && this.newContent.keyElements[0] === ''))
    )
      delete this.newContent.keyElements
    await ContentService.createOne(this.newContent)
    this.changeLoading()
    this.$router.push('/content-list')
  }
  changeLoading(): void {
    this.submitLoading = !this.submitLoading
  }
}
