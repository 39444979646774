


































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import { FileTypeFormat, SIZE_MAX } from '@bertazzoni/common/src/helpers/utils/fileManager'
import axios from 'axios'

@Component({
  components: { Icons }
})
export default class Uploader extends Vue {
  @Prop({ required: true }) format!: FileTypeFormat
  @Prop({ default: true }) reset!: boolean

  private file: File = null
  private errorFile = ''
  private uploading: boolean | number = false

  remove(): void {
    this.file = null
    this.$emit('file-uploaded', '')
  }
  dragover(event: DragEvent): void {
    event.preventDefault()
    if (!(event.target as Element).classList.contains('bg-gray-300')) {
      ;(event.target as Element).classList.add('bg-gray-300')
    }
  }
  dragleave(event: DragEvent): void {
    ;(event.target as Element).classList.remove('bg-gray-300')
  }
  drop(event: DragEvent): void {
    event.preventDefault()
    this.file = event.dataTransfer.files[0]
    ;(event.target as Element).classList.remove('bg-gray-300')
  }
  checkError(newFile: File): boolean {
    const format = newFile.name.split('.')[newFile.name.split('.').length - 1].toLowerCase()
    if (this.format === FileTypeFormat.content) {
      this.$emit('file-type', format)
    }
    if (!this.checkFormat(format)) {
      this.errorFile = 'format'
      return false
    }
    if (newFile.size > SIZE_MAX) {
      //> 500 Mo
      this.errorFile = 'size'
      return false
    }
    return true
  }
  checkFormat(uploadFormat: string): boolean {
    const formatList: string[] = this.format.replaceAll('.', '').split(',')
    const formatOk = formatList.findIndex((format: string) => format === uploadFormat) !== -1
    return formatOk
  }

  async uploadFile(): Promise<void> {
    this.$emit('loading', true)
    const formData = new FormData()
    formData.append('file', this.file)
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_ENDPOINT}/modules/uploadContent`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            this.uploading = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          }
        }
      )
      this.$emit('file-uploaded', response.data)
    } catch {
      this.uploading = 999
      this.$emit('file-uploaded', '')
    }
    this.$emit('loading', false)
  }

  @Watch('file')
  fileChange(newValue: File): void {
    if (newValue) {
      if (!this.checkError(newValue)) {
        this.file = null
      } else {
        this.errorFile = ''
        this.uploadFile()
      }
    }
  }
  @Watch('reset')
  resetUpload(): void {
    this.remove()
  }
}
