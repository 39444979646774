import EntitiesService from '@bertazzoni/common/src/services/EntitiesService'
import { UserType } from '@bertazzoni/common/src/models/user.model'
import store from '../../store'

export const availableLanguages: string[] = ['English', 'Italian', 'Spanish', 'French']

export async function getLanguageList(filtered?: boolean): Promise<string[]> {
  if (store.getters['User/currentUser'].type !== UserType.bertazzoniAdmin && filtered) {
    return await EntitiesService.findAllLanguages()
  } else {
    return availableLanguages
  }
}

export const POINT_BY_QUESTION = 5
export const STAT_ANALITYCS =
  'https://analytics.google.com/analytics/web/#/p272178848/reports/defaulthome'
