import axios from 'axios'
import { Entity, EntityType, NewEntityDto } from '../models/entity.model'

const API_URL = process.env.VUE_APP_API_ENDPOINT + '/entities'

class EntitiesService {
  async findAllLanguages(): Promise<string[]> {
    const response = await axios.get(`${API_URL}/findAllLanguages`)
    return response.data
  }

  async findEntitiesByType(type: EntityType, parent?: string): Promise<string[]> {
    let url = `${API_URL}/findEntitiesBy/${type}`
    if (parent) url += `?parent=${parent}`
    const response = await axios.get(url)
    return response.data
    //return response.data ? (response.data as string[]) : null
  }

  async findAllPaginated(data: {
    limit: number
    offset: number
    title: string
    entityType: EntityType
  }): Promise<{ limit: number; offset: number; results: Entity[]; total: number }> {
    let url = `${API_URL}/findAllPaginated/filter?limit=${data.limit}`
    if (data.offset) url += `&offset=${data.offset}`
    if (data.title && data.title !== '') url += `&title=${data.title}`
    if (data.entityType) url += `&entityType=${data.entityType}`
    const response = await axios.get(url)
    return response.data //change for object
  }

  async createOne(newEntity: NewEntityDto): Promise<void> {
    const response = await axios.post(`${API_URL}/createOne`, newEntity)
    return response.data
  }

  async disableOne(entityId: string): Promise<void> {
    await axios.put(`${API_URL}/disableEntity/${entityId}`)
  }

  async enableOne(entityId: string): Promise<void> {
    await axios.put(`${API_URL}/enableEntity/${entityId}`)
  }
}

export default new EntitiesService()
