// Warning : respect the format : '.format,.format,.format' without space or other caracter than , and .
export enum FileTypeFormat {
  content = '.pdf,.mp4,.ppt,.pptx',
  image = '.jpg,.jpeg,.png',
  csv = '.csv'
}

export enum VideoFormat {
  mp4 = 'mp4'
}
export enum PptFormat {
  ppt = 'ppt',
  pptx = 'pptx'
}
export enum PdfFormat {
  pdf = 'pdf'
}

export const SIZE_MAX = 500000000
